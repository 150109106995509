function formatDayWithOrdinalSuffix(day) {
  if (day >= 11 && day <= 13) {
    return day + 'th';
  }
  switch (day % 10) {
    case 1: return day + 'st';
    case 2: return day + 'nd';
    case 3: return day + 'rd';
    default: return day + 'th';
  }
}

function getNextWorkday() {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const today = new Date();
  const dayOfWeek = today.getDay();
  
  // Calculate the offset to the next workday
  let offset = 1; // Default to next day (Monday to Friday)
  
  if (dayOfWeek === 5) { // Friday
    offset = 3; // Set to next Monday
  } else if (dayOfWeek === 6) { // Saturday
    offset = 2; // Set to next Monday
  }
  
  // Calculate the next workday
  const nextWorkday = new Date(today);
  nextWorkday.setDate(today.getDate() + offset);
  
  const dayName = daysOfWeek[nextWorkday.getDay()];
  const dayOfMonth = formatDayWithOrdinalSuffix(nextWorkday.getDate());
  
  return `${dayName} ${dayOfMonth}`;
}

const nextWorkdayFormatted = getNextWorkday();
console.log("Next workday:", nextWorkdayFormatted);

// Get all elements by their data-id attribute
const nextSlotElements = document.querySelectorAll('[data-id="next-slot"]');

if (nextSlotElements.length > 0) {
  nextSlotElements.forEach(element => {
    element.textContent = nextWorkdayFormatted;
  });
} else {
  console.error("Elements not found with data-id 'next-slot'");
}

/* USING WINDOW. IN FRONT OF GTAG TO FIX PARCEL MINIMISATION */
window.fireConversion = function fireConversion() {
  gtag('event', 'conversion', {'send_to': 'AW-11166055251/BVKtCOXjoN0YENP2scwp'});
  // Optionally, you can add additional code here to handle the click behavior.
  console.log('conversion');
}

function setupZoomer() {
  const zoomerBoxes = document.querySelectorAll('.img-zoomer-box');

  zoomerBoxes.forEach(zoomerBox => {
    const originalImage = zoomerBox.querySelector('.img-1');
    let magnifiedImage = document.createElement('div');
    magnifiedImage.className = 'img-2';
    magnifiedImage.style.backgroundImage = `url('${originalImage.src}')`;
    originalImage.parentNode.insertBefore(magnifiedImage, originalImage.nextSibling);

    zoomerBox.addEventListener('mousemove', function (e) {
      const style = magnifiedImage.style;
      const x = e.pageX - this.offsetLeft;
      const y = e.pageY - this.offsetTop;
      const imgWidth = originalImage.offsetWidth;
      const imgHeight = originalImage.offsetHeight;
      let xperc = ((x / imgWidth) * 100);
      let yperc = ((y / imgHeight) * 100);

      if (x > 0.01 * imgWidth) {
        xperc += 0.15 * xperc;
      }

      if (y >= 0.01 * imgHeight) {
        yperc += 0.15 * yperc;
      }

      style.backgroundPositionX = xperc - 9 + '%';
      style.backgroundPositionY = yperc - 9 + '%';
      style.left = x - 180 + 'px';
      style.top = y - 180 + 'px';
    }, false);
  });
}

setupZoomer();


// A simple hash function for demonstration purposes
function simpleHash(s) {
  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    const char = s.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

function checkCssFeature(feature) {
return CSS.supports(feature);
}

function checkApiAvailability(api) {
return window[api] !== undefined;
}

function prefersDarkMode() {
return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}

function hasTouchSupport() {
return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

function getScreenProperties() {
return {
  width: screen.width,
  height: screen.height,
  colorDepth: screen.colorDepth
};
}


function generateUID() {
const userAgent = navigator.userAgent;
const language = navigator.language;
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const darkMode = prefersDarkMode() ? 'dark' : 'light';
const touchSupport = hasTouchSupport() ? 'touch' : 'no-touch';
const screenProps = getScreenProperties();
const cssGridSupport = checkCssFeature('display: grid') ? 'grid' : 'no-grid';

const uidSource = `${userAgent}-${language}-${timeZone}-${darkMode}-${touchSupport}-${screenProps.width}x${screenProps.height}-${screenProps.colorDepth}-${cssGridSupport}`;
const uid = simpleHash(uidSource);

return uid;
}





document.addEventListener('DOMContentLoaded', function() {
  // Send page view event
  const bodyLabel = document.body.getAttribute('data-event-label');
  if (bodyLabel) {
    sendMessage('View', '', bodyLabel); // 'group' is empty for page views
  }

  // Handle button clicks
  const buttons = document.querySelectorAll('[data-event-type="Click"]');
  buttons.forEach(button => {
    button.addEventListener('click', function() {
      const type = button.getAttribute('data-event-type');
      const group = button.getAttribute('data-event-group');
      const label = button.getAttribute('data-event-label');
      sendMessage(type, group, label);
    });
  });
});

function sendMessage(type, group, label) {
  const UID = generateUID(); // Existing UID generation
  const currentURL = window.location.href; // Capture the current URL

  fetch('/.netlify/functions/sendMessage', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ UID, type, group, label, url: currentURL }), // Include currentURL in the payload
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.text();
  })
  .then(data => console.log('Message sent successfully:', data))
  .catch(error => console.error('Error:', error));
}



// // Using require
// const hljs = require('highlight.js/lib/core');

// // Load any languages you need
// hljs.registerLanguage('javascript', require('highlight.js/lib/languages/javascript'));


// // Copy code buttons
// const copyButtonLabel = "Copy Code";

// // use a class selector if available
// let blocks = document.querySelectorAll("pre");

// blocks.forEach((block) => {
//   // only add button if browser supports Clipboard API
//   if (navigator.clipboard) {
//     let button = document.createElement("button");

//     button.innerText = copyButtonLabel;
//     block.appendChild(button);

//     button.addEventListener("click", async () => {
//       await copyCode(block);
//     });
//   }
// });

// async function copyCode(block) {
//   let code = block.querySelector("code");
//   let text = code.innerText;

//   await navigator.clipboard.writeText(text);
// }

document.querySelectorAll('details').forEach((elem) => {
  elem.addEventListener('toggle', function(event) {
      if (this.open) {
          // Close all other details elements
          document.querySelectorAll('details').forEach((otherElem) => {
              if (otherElem !== this) {
                  otherElem.removeAttribute('open');
              }
          });
      }
  });
});